// duplicate of src/pages/verify.tsx
// somehow some users comes with a direct link to this route, /verifyNumber
import React from 'react';

import { VerifyPage } from '@/features/auth';

export function Head() {
  return <title>Super Champs HQ Wallet | Verify</title>;
}

export default VerifyPage;
